import React, { useContext } from "react";

import { ModalContext } from "../Layout";
import ContainerFluid from "../Layout/ContainerFluid";
import { Button } from "../Molecules/Buttons";

import Icons from "../../misc/Icons";
import DeviceFrame from "../../misc/DeviceFrame";


// Data
const HeroData = {
  title: "Erdőleltározás távérzékeléssel",
  lead: "Erdei utak, kilátók, víztározók építéséhez. Fatérfogat mennyiség meghatározásához. Befektetési célú erdőterület értékének megállapításához.\n Jelölje ki vagy töltse fel az érdeklődési területet! A felmérést már elvégeztük, szállítjuk az erdészeti adatokat.", 
  keyList: [
    {
      icon: <Icons.Steps/>,
      label: "Erdőterületek domborzat- és famagasságmodellje"
    },
    {
      icon: <Icons.Camera/>,
      label: "Lombmentes állapotban készült digitális ortofotó"
    },
    {
      icon: <Icons.Tree/>,
      label: "Faegyedek térinformatikai adatbázisa"
    }
  ]
};

// Component
const Hero = () => {
  const { modalOpen } = useContext(ModalContext);
  const leadParagraphs = HeroData.lead.split('\n');

  return (
    <>
      <ContainerFluid>
        <section className="--hero-section">
          <div className="--hero-section__container --container">

            <div className="--hero-section__content">
              <div className="--hero-section__content-copy">
                <h1>{HeroData.title}</h1>
                {leadParagraphs.map((paragraph, i) => (
                  <p key={i}>{paragraph}</p>
                ))}
              </div>
              <div className="--hero-section__content-keylist">
                {HeroData.keyList.map((keyItem, i) => {
                  return (
                    <div
                      key={i}
                      className="--hero-section__content-keylist__item"
                    >
                      {keyItem.icon}
                      <h5>{keyItem.label}</h5>
                    </div>
                  )
                })}
              </div>
              <div className="--hero-section__content-buttons">
                <Button
                  text="Regisztráció"
                  action={() => {modalOpen(true)}}
                />
                <Button
                  text="Kapcsolatfelvétel"
                  url="#contact-section"
                  secondary
                />
              </div>
            </div>
            
            <div className="--hero-section__cover">
              <div className="--hero-section__cover-positioner">
                <DeviceFrame fit>
                  <video
                    className="child"
                    poster="Hero.jpg"
                    autoPlay
                    muted
                    loop
                    playsInline
                  >
                    <source type="video/mp4" />
                  </video>
                </DeviceFrame>
              </div>
            </div>

          </div>
        </section>
      </ContainerFluid>
    </>
  )
};

export default Hero;
