import React from "react"
import Logo from "../../misc/Logo"

const Footer = () => {
  return (
    <footer>
      <div className="footer__container">
        <div className="footer__logo">
          <Logo color="white"/>
        </div>
        <ul className="footer_list">
          <li>
            <a
              className="--clr-shades-white"
              href="https://map.envimap.hu/tc-resolver?service=Erdő"
              target="_blank"
              rel="noreferrer"
            >
              Általános Szerződési Feltételek
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
