import React from "react";

import Illustrations from "../../misc/Illustrations";


// Data
const USPData = [
  {
    illustration: <Illustrations.LidarSensing/>,
    title: "Felmérjük a levegőből",
    text: "A felmérést mi végezzük Ön helyett légi LiDAR technológia segítségével, melyből a kapott adatokat térinformatikai környezetben feldolgozzuk, majd a méreteket, kimutatásokat készen adjuk át a megrendelőnek.",
  },
  {
    illustration: <Illustrations.SaveMoney/>,
    title: "Csökkentse kiadásait",
    text: "A helyszíni felmérésre fordított arányos bérköltség, termelésből kiesett munkaidő, üzemanyagköltség forintosítva jelentős mértéket öltenek. Termékeink használatával Önnél tartjuk a pénzt.",
  },
  {
    illustration: <Illustrations.Downloadable/>,
    title: "Tervezzünk együtt",
    text: "A termékünk megrendelésével nagy pontosságú térbeli modellek készülnek, majd az ebből származtatott erdészeti adatokat letöltés után azonnal beépítheti a tervezési, gazdálkodási, értékesítési rendszerébe.",
  },
  {
    illustration: <Illustrations.EcoFriendly/>,
    title: "Óvjuk környezetünket",
    text: "Termékeink megrendelésével hozzájárul az ökológiai lábnyomunk csökkentéséhez, hiszen helyszíni kiszállásainak száma jelentős mértékben kiváltható.",
  },
];

// Component
const USPSection = () => {
  return (
    <section className="--usp-section">
      {USPData.map((usp, i) => {
        return (
          <div key={i}>
            {usp.illustration}
            <div className="--usp-section__content">
              <h4>{usp.title}</h4>
              <p>{usp.text}</p>
            </div>
          </div>
        )
      })}
    </section>
  )
}

export default USPSection;
