import React from "react"

// Set default icon color
//
// To add a new color, create a new case with the value and
// name of the color.

let fillColor
const ColorSwitch = propColor => {
  switch (propColor) {
    case "green":
      fillColor = "#00FF7F"
      break
    case "white":
      fillColor = "#FFFFFF"
      break
    case "notify":
      fillColor = "#FF0055"
      break
    default:
      fillColor = "#0A0A29"
      break
  }
  return fillColor
}

// Icons
//
// To add an icon, create an arrow function with the name of the icon,
// give it the input value "props", call the ColorSwitch function at
// the beginning with the value "props.color" and return the svg code.

const Add = props => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M21 11.001h-8v-8h-2v8H3v2h8v8h2v-8h8v-2z"/>
      </g>
    </svg>
  )
}

const ArrowDown = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"/>
      </g>
    </svg>
  )
}

const ArrowBackward = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"/>
      </g>
    </svg>
  )
}

const ArrowForward = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M12 4L10.5867 5.41333L16.1733 11H4V13H16.1733L10.5867 18.5867L12 20L20 12L12 4Z"/>
      </g>
    </svg>
  )
}

const Cookie = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M17 3C17 3.55228 16.5523 4 16 4C15.4477 4 15 3.55228 15 3C15 2.44772 15.4477 2 16 2C16.5523 2 17 2.44772 17 3Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12C5 10.346 6.346 9 8 9C9.654 9 11 10.346 11 12C11 13.654 9.654 15 8 15C6.346 15 5 13.654 5 12ZM7 12C7 12.551 7.449 13 8 13C8.551 13 9 12.551 9 12C9 11.449 8.551 11 8 11C7.449 11 7 11.449 7 12Z"
      />
      <path
        fill={fillColor}
        d="M16.001 15C16.5533 15 17.001 14.5523 17.001 14C17.001 13.4477 16.5533 13 16.001 13C15.4487 13 15.001 13.4477 15.001 14C15.001 14.5523 15.4487 15 16.001 15Z"
      />
      <path
        fill={fillColor}
        d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.61 9.89105C20.892 9.80005 21.201 9.83905 21.453 9.99805C21.704 10.1571 21.871 10.4191 21.908 10.7151C21.971 11.1991 22 11.6071 22 12.0001C22 17.5151 17.514 22.0001 12 22.0001C6.486 22.0001 2 17.5151 2 12.0001C2 6.67505 6.169 2.29205 11.492 2.02505C11.857 2.00405 12.203 2.18905 12.394 2.49905C12.586 2.81105 12.592 3.20205 12.41 3.51905C12.139 3.99505 12 4.49305 12 5.00005C12 6.65405 13.346 8.00005 15 8.00005C15.531 8.00005 16.045 7.85305 16.529 7.56405C16.817 7.39205 17.171 7.37505 17.474 7.52005C17.776 7.66405 17.986 7.95105 18.033 8.28205C18.172 9.26105 19.018 10.0001 20 10.0001C20.184 10.0001 20.377 9.96505 20.61 9.89105ZM4 12.0001C4 16.4111 7.589 20.0001 12 20.0001C16.411 20.0001 20 16.4111 20 12.0001C18.464 12.0001 17.095 11.1041 16.434 9.78205C15.966 9.92605 15.482 10.0001 15 10.0001C12.243 10.0001 10 7.75705 10 5.00005C10 4.74705 10.02 4.49605 10.059 4.24805C6.561 5.11505 4 8.26805 4 12.0001Z"
      />
      <path
        fill={fillColor}
        d="M21 6C21.5523 6 22 5.55228 22 5C22 4.44772 21.5523 4 21 4C20.4477 4 20 4.44772 20 5C20 5.55228 20.4477 6 21 6Z"
      />
    </svg>
  );
};

const Chevron = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"/>
      </g>
    </svg>
  )
}

const Done = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M9.00002 16.2001L4.80002 12.0001L3.40002 13.4001L9.00002 19.0001L21 7.0001L19.6 5.6001L9.00002 16.2001Z"/>
      </g>
    </svg>
  )
}

const Hamburger = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <rect x="1" y="5" width="22" height="2"/>
        <rect x="1" y="11" width="22" height="2"/>
        <rect x="1" y="17" width="22" height="2"/>
      </g>
    </svg>
  )
}

const Close = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M20.749 4.707l-1.415-1.414-7.292 7.293-7.293-7.293-1.415 1.414L10.627 12l-7.293 7.293 1.415 1.414 7.293-7.293 7.292 7.293 1.415-1.414L13.456 12l7.293-7.293z"/>
      </g>
    </svg>
  )
}

const Mail = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"/>
      </g>
    </svg>
  )
}

const Phone = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M6.62 10.79C8.06 13.62 10.38 15.93 13.21 17.38L15.41 15.18C15.68 14.91 16.08 14.82 16.43 14.94C17.55 15.31 18.76 15.51 20 15.51C20.55 15.51 21 15.96 21 16.51V20C21 20.55 20.55 21 20 21C10.61 21 3 13.39 3 4C3 3.45 3.45 3 4 3H7.5C8.05 3 8.5 3.45 8.5 4C8.5 5.25 8.7 6.45 9.07 7.57C9.18 7.92 9.1 8.31 8.82 8.59L6.62 10.79Z"/>
      </g>
    </svg>
  )
}

const Substract = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M21 11.001H3V13.001H21V11.001Z"/>
      </g>
    </svg>
  )
}

const User = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M16.002 8C16.002 10.205 14.208 12 12.002 12C9.79701 12 8.00201 10.205 8.00201 8C8.00201 5.795 9.79601 4 12.002 4C14.208 4 16.002 5.795 16.002 8Z"/>
        <path d="M4.00201 19C4.00201 15.467 7.29201 13 12.002 13C16.713 13 20.002 15.467 20.002 19V20H4.00201V19Z"/>
      </g>
    </svg>
  )
}

const Steps = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3 22C2.44772 22 2 21.5523 2 21L2 15C2 14.4477 2.44772 14 3 14H8V9C8 8.44771 8.44772 8 9 8H14L14 3C14 2.44772 14.4477 2 15 2H21C21.5523 2 22 2.44772 22 3V21C22 21.5523 21.5523 22 21 22H7C6.44772 22 6 21.5523 6 21C6 20.4477 6.44772 20 7 20H20V4H16V9C16 9.55229 15.5523 10 15 10H10V15C10 15.5523 9.55228 16 9 16H4V21C4 21.5523 3.55228 22 3 22Z"
        />
      </g>
    </svg>
  )
}

const Camera = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 12.999C7 10.242 9.243 7.99902 12 7.99902C14.757 7.99902 17 10.241 17 12.999C17 15.757 14.757 17.999 12 17.999C9.243 17.999 7 15.757 7 12.999ZM9 12.999C9 14.653 10.346 15.999 12 15.999C13.654 15.999 15 14.653 15 12.999C15 11.345 13.654 9.99902 12 9.99902C10.346 9.99902 9 11.345 9 12.999Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.535 5.99902H20C21.103 5.99902 22 6.89602 22 7.99902V18.999C22 20.102 21.103 20.999 20 20.999H4C2.897 20.999 2 20.102 2 18.999V7.99902C2 6.89602 2.897 5.99902 4 5.99902H7.465L8.871 3.89002C9.243 3.33202 9.865 2.99902 10.535 2.99902H13.465C14.135 2.99902 14.757 3.33202 15.129 3.89002L16.535 5.99902ZM4 7.99902V18.999H20.001L20 7.99902H15.465L13.465 4.99902H10.535L8.535 7.99902H4Z"
        />
      </g>
    </svg>
  )
}

const Tree = props => {
  ColorSwitch(props.color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M12 1C9.93506 1 8.16429 2.25104 7.40102 4.03549C5.28341 4.28822 3.57303 5.86414 3.1186 7.91338C1.83853 8.81752 1 10.3103 1 12C1 14.7614 3.23858 17 6 17C6.21777 17 6.43277 16.986 6.64404 16.9588C7.1918 16.8882 7.57863 16.387 7.50805 15.8392C7.43746 15.2914 6.9362 14.9046 6.38845 14.9752C6.26171 14.9915 6.13209 15 6 15C4.34315 15 3 13.6569 3 12C3 10.882 3.61107 9.90549 4.52221 9.38842L4.96142 9.13918L5.02154 8.63778C5.1997 7.15216 6.46604 6 8 6C8.03172 6 8.06329 6.00049 8.09471 6.00146L8.89492 6.02612L9.09408 5.2507C9.42666 3.95584 10.603 3 12 3C13.397 3 14.5733 3.95584 14.9059 5.25071L15.1051 6.02613L15.9053 6.00146C15.9368 6.00049 15.9683 6 16 6C17.534 6 18.8003 7.15216 18.9785 8.63777L19.0386 9.13918L19.4778 9.38842C20.3889 9.90548 21 10.882 21 12C21 13.6569 19.6569 15 18 15C17.8679 15 17.7383 14.9915 17.6116 14.9752C17.0638 14.9046 16.5625 15.2914 16.492 15.8392C16.4214 16.387 16.8082 16.8882 17.356 16.9588C17.5672 16.986 17.7822 17 18 17C20.7614 17 23 14.7614 23 12C23 10.3103 22.1615 8.81752 20.8814 7.91338C20.427 5.86414 18.7166 4.28822 16.599 4.03549C15.8357 2.25104 14.0649 1 12 1Z"/>
        <path d="M7.5 11C6.94772 11 6.5 11.4477 6.5 12C6.5 12.5523 6.94772 13 7.5 13C8.34586 13 8.79657 13.2108 9.04289 13.4571C9.28921 13.7034 9.5 14.1541 9.5 15V21H8C7.44772 21 7 21.4477 7 22C7 22.5523 7.44772 23 8 23H16C16.5523 23 17 22.5523 17 22C17 21.4477 16.5523 21 16 21H14.5V15C14.5 14.1541 14.7108 13.7034 14.9571 13.4571C15.2034 13.2108 15.6541 13 16.5 13C17.0523 13 17.5 12.5523 17.5 12C17.5 11.4477 17.0523 11 16.5 11C15.3459 11 14.2966 11.2892 13.5429 12.0429C12.7892 12.7966 12.5 13.8459 12.5 15V21H11.5V15C11.5 13.8459 11.2108 12.7966 10.4571 12.0429C9.70343 11.2892 8.65414 11 7.5 11Z"/>
      </g>
    </svg>
  )
}


const Icons = {
  Add,
  ArrowDown,
  ArrowBackward,
  ArrowForward,
  Cookie,
  Chevron,
  Done,
  Hamburger,
  Close,
  Mail,
  Phone,
  Substract,
  User,
  Steps,
  Camera,
  Tree,
}

export default Icons
