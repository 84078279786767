import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Icons from "../../misc/Icons";


// Data
const ProductsData = {
  title: "Elérhető termékeink",
  products: [
    {
      title: "Digitális domborzatmodell - DDM",
      text: "A talajfelszín modellje, 1*1 méter felbontású raszterháló, Balti-tenger feletti magassági értékekkel, 5-10 centiméteres vertikális pontossággal.",
      usage: [
        "Lejtőviszonyok és kitettség elemzéséhez",
        "Szintvonalas térkép előállításához",
        "Vízelvezetés/vízvisszatartás tervezéséhez",
        "Erdőfeltárás optimalizálásához",
      ],
    },
    {
      title: "Digitális felszínmodell - DFM",
      text: "A növényzetet és az épített környezetet is tartalmazó borított felszín modellje, 1*1 méter felbontású raszterháló, Balti-tenger feletti magassági értékekkel, 5-10 centiméteres vertikális pontossággal.",
      usage: [
        "A terepi objektumok abszolút magasságának megállapításához",
        "Láthatósági vizsgálatokhoz (pl. erdei kilátóból szemrevételezhető környezet)",
      ],
    },
    {
      title: "Digitális relatív magasságmodell - DRM",
      text: "A felszínmodell és a domborzatmodell különbségeként számított magasságmodell, 1*1 méter felbontású raszterháló, talajszint feletti magassági értékekkel.",
      usage: [
        "Faegyedek, faállományok magasságának megállapításához",
        "Épített objektum-magasság vizsgálatához (pl. kilátók, magaslesek, épületek)",
      ],
    },
    {
      title: "Digitális ortofotó",
      text: "Lombmentes állapotban, a lézerszkenneléssel azonos időben készült RGB színes ortofotó, 20 cm terepi felbontás.",
      usage: [
        "Vizuális interpretációhoz",
        "Elsősorban fenyves/lombos állományrészek elkülönítéséhez",
      ],
    },
    {
      title: "Faegyedek térinformatikai adatbázisa",
      text: "Tetszőlegesen kijelölt területre minden egyes faegyedre: pozíció, koronapoligon, átmérő, magasság, fatérfogat, fafaj/fafajcsoport (ESRI SHP és TopoLynx Map formátumban).",
      usage: [
        "Erdőleltárhoz",
        "Összesített faállomány-adatokhoz",
      ],
    }
  ]
};

// Component
const Products = () => {
  const images = useStaticQuery(graphql`
    query products {
      allFile(
        filter: {
          absolutePath: { regex: "/(products)/" }
          sourceInstanceName: { ne: "markdown" }
        }
        sort: {fields: name}
      ) {
        edges {
          node {
            childrenImageSharp {
              gatsbyImageData(
                blurredOptions: { toFormat: WEBP }
                placeholder: BLURRED
                formats: WEBP
                breakpoints: [378, 672, 980, 1200, 1568]
              )
            }
          name
            sourceInstanceName
          }
        }
      }
    }
  `)
  const productsImg = images.allFile;

  return (
    <section className="--products-section">
      <h2>{ProductsData.title}</h2>
      <div>
        {ProductsData.products.map((product, index) => {
          return (
            <div
              key={index}
              className="--products-section__item"
            >
              <div className="--products-section__item__img">
                <GatsbyImage
                  className={"--products-section__item__img-wrapper"}
                  image={getImage(
                    productsImg.edges[index].node.childrenImageSharp[0]
                      .gatsbyImageData
                  )}
                  loading={"lazy"}
                  alt={productsImg.edges[index].node.name}
                />
              </div>
              <div className="--products-section__item__content-wrapper">
                <h3>{product.title}</h3>
                <p>{product.text}</p>
                <ul>
                  {product.usage.map((usage, index) => {
                    return (
                      <li key={index}>
                        <Icons.Done color="green"/>
                        <span>{usage}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
};

export default Products;
