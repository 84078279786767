import React from "react"

let fillColor
const ColorSwitch = propColor => {
  switch (propColor) {
    case "white":
      fillColor = "#FFFFFF"
      break
    default:
      fillColor = "#0A0A29"
      break
  }
  return fillColor
}

const Logo = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="24"
      fill="none"
      viewBox="0 0 104 24"
    >
      <path
        fill="#00FF7F"
        d="M67.391 20.808L67.8652 19.8451C67.8422 19.9631 67.8299 20.0824 67.8299 20.2032C67.8299 20.4087 67.8649 20.6103 67.9309 20.8082H67.391V20.808ZM68.3727 18.8154L75.3793 4.59455C75.607 4.13239 75.9435 3.75747 76.3566 3.49075C76.8119 3.1969 77.3451 3.03838 77.9194 3.0381V3.03223C78.4892 3.03223 79.0199 3.19075 79.4761 3.4846V3.49047C79.8929 3.75943 80.2312 4.13239 80.4578 4.58896L80.4602 4.58812L87.5384 18.9219C86.7458 17.85 84.9856 16.9595 82.6919 16.435L77.9191 6.76972L73.1595 16.4299C70.9309 16.9363 69.203 17.7876 68.3727 18.8159V18.8154ZM87.9368 19.7285L88.47 20.808H87.8981C87.9641 20.6103 87.9991 20.4084 87.9991 20.2029C87.9991 20.0425 87.9776 19.8842 87.9371 19.7285H87.9368Z"
      />
      <path
        fill={fillColor}
        d="M48.5425 20.7622L48.598 5.37633C48.5999 4.83198 48.7997 4.32872 49.1441 3.92836C49.4913 3.5252 49.9791 3.2266 50.5543 3.09463L50.554 3.09324C51.1311 2.96211 51.7147 3.01523 52.2264 3.22101C52.7403 3.4279 53.1777 3.78633 53.4595 4.26386L57.6208 11.3119L61.8036 4.24177C62.0869 3.76284 62.5243 3.40497 63.0376 3.19892C63.5518 2.99287 64.1359 2.9403 64.7109 3.07227C65.2861 3.20451 65.7739 3.50423 66.1202 3.90907H66.1266C66.4723 4.31335 66.6712 4.81716 66.6712 5.35843H66.6647L66.6611 20.8424H63.3682L63.3713 7.92642L59.8979 13.7974C59.671 14.1813 59.3441 14.4894 58.9481 14.7047C58.5537 14.9191 58.1009 15.0343 57.6199 15.0343V15.0402C57.145 15.0402 56.6922 14.9222 56.2919 14.7044C55.8953 14.4883 55.5684 14.1799 55.3425 13.7971L51.882 7.93593L51.8356 20.7624L48.5425 20.7622Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.0337 20.7622L89.0628 5.59776C89.0634 5.24436 89.1337 4.91473 89.2725 4.61138C89.414 4.30272 89.6209 4.02425 89.8926 3.7785C90.1621 3.5347 90.4684 3.34878 90.8089 3.22185C91.149 3.09491 91.5112 3.03201 91.8925 3.03341L96.555 3.05354V3.04766H96.5597C98.6136 3.04878 100.473 3.80786 101.819 5.03384C103.167 6.26093 104 7.95634 104 9.82844C104 11.6891 103.172 13.378 101.832 14.6063C100.495 15.8306 98.6452 16.5924 96.5962 16.6036H92.3342L92.3262 20.7624L89.0337 20.7622ZM92.3551 6.03419L92.3404 13.6042H96.5836C97.7197 13.5981 98.7486 13.1728 99.4946 12.489C100.237 11.8093 100.695 10.8691 100.695 9.82816C100.695 8.78698 100.231 7.8445 99.4823 7.16232C98.7336 6.48013 97.6985 6.05824 96.5554 6.05824V5.61621L96.5535 6.05236L92.3551 6.03419Z"
      />
      <path
        fill={fillColor}
        d="M68.5102 18.5363C68.9335 18.3028 69.3961 18.0881 69.8934 17.8935C71.9916 17.0732 74.7902 16.5722 77.8246 16.5722C80.6948 16.5722 83.3713 17.024 85.439 17.7767H85.4454C86.1813 18.0445 86.848 18.3557 87.4315 18.7052L88.4697 20.8074H84.8512L84.8429 20.7903V20.8113C84.647 20.7266 84.4386 20.6441 84.2185 20.5638L84.2194 20.5619C82.5357 19.9502 80.2917 19.5828 77.8243 19.5828C75.2295 19.5828 72.8901 19.9901 71.1842 20.6569C71.0578 20.7064 70.9359 20.7565 70.8183 20.8074H67.3907L68.5102 18.5363Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.42756 9.32425C9.91514 9.32425 10.9914 9.63068 11.656 10.2432C12.3206 10.8558 12.653 11.7172 12.653 12.8269C12.653 13.8793 12.3285 14.69 11.6796 15.2593C11.0307 15.8285 9.93877 16.1134 8.40361 16.1134H2.44502V16.9351C2.44502 17.3964 2.59144 17.7747 2.88429 18.0702C3.17714 18.3658 3.67167 18.5134 4.36788 18.5134H12.0357V20.7403H4.36788C3.57651 20.7403 2.90793 20.6466 2.36183 20.4593C1.81573 20.272 1.36479 20.0089 1.0087 19.67C0.652618 19.3312 0.395378 18.935 0.237288 18.4809C0.0788913 18.0269 0 17.5259 0 16.9782V13.0863C0 12.5529 0.0791983 12.0558 0.237288 11.5945C0.395378 11.1332 0.652618 10.7334 1.0087 10.3945C1.36479 10.0556 1.81573 9.79284 2.36183 9.60524C2.90793 9.41791 3.57651 9.32425 4.36788 9.32425H8.42756ZM2.44532 14.3188H8.33271C9.01326 14.3188 9.49183 14.1963 9.76902 13.9511C10.0459 13.7062 10.1843 13.3746 10.1843 12.9566C10.1843 12.5241 10.0419 12.1783 9.75705 11.9188C9.47218 11.6593 9.02124 11.5296 8.40392 11.5296H4.36819C3.67198 11.5296 3.17714 11.6809 2.8846 11.9837C2.59175 12.2865 2.44532 12.6611 2.44532 13.1079V14.3188Z"
      />
      <path
        fill={fillColor}
        d="M21.1281 9.32425C23.2647 9.32425 24.8514 9.7604 25.8877 10.6324C26.9244 11.5045 27.4425 12.7332 27.4425 14.3188V20.7403H24.9023V14.3188C24.9023 13.4107 24.6055 12.7187 24.0121 12.2431C23.4188 11.7675 22.4573 11.5296 21.1278 11.5296H17.0209C16.8152 11.5296 16.7124 11.6378 16.7124 11.8539V20.7403H14.1961V11.0971C14.1961 10.4197 14.3306 9.95472 14.5998 9.70253C14.8687 9.45034 15.32 9.32425 15.9529 9.32425H21.1281Z"
      />
      <path
        fill={fillColor}
        d="M30.2673 9.32425L34.4454 18.4918C34.5403 18.7225 34.7463 18.838 35.0627 18.838C35.3952 18.838 35.6168 18.7225 35.7273 18.4918L40.0716 9.32425H42.7778L37.9351 19.5081C37.6659 20.0558 37.2981 20.4414 36.8312 20.6648C36.3643 20.8882 35.7749 21 35.0627 21C34.3349 21 33.7373 20.8848 33.2703 20.6542C32.8034 20.4235 32.4514 20.0416 32.2141 19.5081L27.6086 9.32425H30.2673Z"
      />
      <path
        fill={fillColor}
        d="M46.0059 9.32425V20.7403H43.4897V9.32425H46.0059Z"
      />
      <path
        fill={fillColor}
        d="M46.3005 6.06606C46.3005 6.84197 45.6098 7.47097 44.7578 7.47097C43.9058 7.47097 43.2151 6.84197 43.2151 6.06606C43.2151 5.29014 43.9058 4.66114 44.7578 4.66114C45.6098 4.66114 46.3005 5.29014 46.3005 6.06606Z"
      />
    </svg>
  )
}

export default Logo
