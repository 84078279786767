import React, { useState, useContext, useEffect } from "react";
import { ModalContext } from "../Layout/index";
import { ButtonClose, Button } from "../Molecules/Buttons";
import Icons from "../../misc/Icons"


// Register Fetch
const Register = async ( body, setThanks, setError ) => {
  await fetch("https://api.envimap.hu/api/v1/users/register", {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: body,
  })
  .then((response) => {
    if (response.status === 200) {
      setThanks(true);
    } else if (response.status === 409) {
      setError("A megadott email már regisztrálva van!")
    } else {
      setError("Hiba lépett fel a regisztráció leadásakor!")
      throw Error(response.statusText);
    }
  })
  .catch(error => alert(error))
};


const Modal = ({ setIsOpen, style }) => {
  return (
    <div className="--modal-layout" style={style}>
      <div className="--modal-layout__centered">
        <RegistrationModal setIsOpen={setIsOpen} />
      </div>
    </div>
  )
}


export const RegistrationModal = () => {
  const { modalOpen } = useContext(ModalContext);
  const [ thanks, setThanks ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState();

  // Error handling
  // Need to implement:
  // - 409 status
  // Missing fields span under input
  const [passwordError, setPasswordError] = useState(false)

  // Input state
  const [input, setInput] = useState({
    company: "",
    companyVat: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    marketSegment: "",
    employeeNumber: "",
    terranPartnerChecked: false,
    tcChecked: false
  })

  // Handle input change
  const handleInput = (e) => {
    setInput(prevInput => {
      const {name, value, type, checked} = e.target
      return {
        ...prevInput,
        [name]: type === "checkbox" ? checked : value
      }
    })
  }

  // Effect to handle form changes
  useEffect(() => {
    // Check if passwords are matching
    if (input.confirmPassword) {
      if (input.password === input.confirmPassword) {
        setPasswordError(false);
      } else {
        setPasswordError(true);
      }
    } else {
      setPasswordError(false);
    }
  }, [input]);

  // Handle special TC scenarios
  const checkTC = () => {
    const aszfLink = "https://map.envimap.hu/tc-resolver?service=Erdő";
    return `<a href="${aszfLink}" target="_blank">Általános Szerződési Feltételek</a>`;
  };

  // Form submit handling
  const handleSubmit = e => {
    e.preventDefault()

    // Create fetch body
    let body = JSON.stringify({
      company: input.company,
      company_vat: input.companyVat,
      first_name: input.firstName,
      last_name: input.lastName,
      email: input.email,
      phone: input.phone,
      market_segment: "Erdő - " + input.marketSegment,
      employee_number: input.employeeNumber,
      password: input.password
    });

    // Check fields
    if (input.companyVat.length < 11) {
      setErrorMessage("Kérjük ellenőrizze a megadott adószámot")
    } else if (input.password !== input.confirmPassword) {
      setErrorMessage("A megadott jelszavak eltérnek")
    } else {
      // Send registration
      Register(body, setThanks, setErrorMessage)
    }
  };


  if (!thanks) {
    return (
      <div className="--modal">
  
        <div className="--modal__header">
          <div className="--modal__header__content">
            <h4>Regisztráljon szolgáltatásunkra</h4>
            <p>Fiókja létrehozásához, kérjük adjon meg pár szükséges adatot.</p>
          </div>
          <ButtonClose
            action={() => modalOpen(false)}
          />
        </div>
  
        <div className="--modal__content">
          
          {errorMessage &&
            <div className="--modal__content__error">
              <span className="span--bold">{errorMessage}</span>
            </div>
          }

          <form
            name="registration form"
            onSubmit={handleSubmit}
            action="/thankyou"
          >
            <div className="--modal__content__fields">

              <CustomInput
                type={"text"}
                name={"company"}
                onChange={handleInput}
                value={input.company}
              >
                Cégnév
              </CustomInput>
              
              <CustomInput
                type={"text"}
                name={"companyVat"}
                onChange={handleInput}
                value={input.companyVat}
              >
                Adószám
              </CustomInput>

              <CustomInput
                type={"text"}
                name={"lastName"}
                onChange={handleInput}
                value={input.lastName}
              >
                Vezetéknév
              </CustomInput>

              <CustomInput
                type={"text"}
                name={"firstName"}
                onChange={handleInput}
                value={input.firstName}
              >
                Keresztnév
              </CustomInput>
              
              <CustomInput
                type={"email"}
                name={"email"}
                onChange={handleInput}
                value={input.email}
              >
                Email-cím
              </CustomInput>
  
              <CustomInput
                type={"tel"}
                name={"phone"}
                onChange={handleInput}
                value={input.phone}
              >
                Telefonszám
              </CustomInput>
              
              <CustomInput
                type={"password"}
                name={"password"}
                onChange={handleInput}
                value={input.password}
                error={passwordError}
              >
                Jelszó
              </CustomInput>
              <CustomInput
                type={"password"}
                name={"confirmPassword"}
                onChange={handleInput}
                value={input.confirmPassword}
                error={passwordError}
              >
                Jelszó újra
              </CustomInput>
              
              <CustomSelect
                name={"marketSegment"}
                options={[
                  { title: "Válasszon", value: "", disabled: true },
                  { title: "Magán erdőgazdálkodó", value: "Magán erdőgazdálkodó" },
                  { title: "Állami erdőgazdálkodó", value: "Állami erdőgazdálkodó" },
                  { title: "Nemzeti park", value: "Nemzeti park" },
                  { title: "Vízügyi igazgatóság", value: "Vízügyi igazgatóság" },
                  { title: "Önkormányzat", value: "Önkormányzat" },
                  { title: "Hatóság", value: "Hatóság" },
                  { title: "Egyéb", value: "Egyéb" },
                ]}
                onChange={handleInput}
                value={input.marketSegment}
              >
                Piaci szegmens
              </CustomSelect>
              
              <CustomSelect
                name={"employeeNumber"}
                options={[
                  { title: "Válasszon", value: "", disabled: true },
                  { title: "1-5 fő", value: "1-5" },
                  { title: "5-20 fő", value: "5-20" },
                  { title: "20+ fő", value: "20+" },
                ]}
                onChange={handleInput}
                value={input.employeeNumber}
              >
                Munkatársak száma
              </CustomSelect>
            </div>
            
            <CustomCheckbox
              name={"tcChecked"}
              isRequired={true}
              label={`Elfogadom az ${checkTC()}-ben leírtakat és hozzájárulok adataim kezeléséhez.`}
              onChange={handleInput}
              value={input.tcChecked}
            />
  
            <Button
              text="Regisztrálok"
              type="submit"
            />
          </form>
        </div>

      </div>
    )
  } else {

    const closeModal = () => {
      modalOpen(false);
      setThanks(false);
    }

    return (
      <div className="--modal --modal-layout__thanks">
        
        <div className="--modal__content">
          <div className="--modal__content-icon">
            <Icons.Done />
          </div>
          <h3>Sikeres regisztráció!</h3>
          <p>
            Felhasználói fiókját hamarosan aktiváljuk.<br/>
            Az EnviMAP Erdő szolgáltatás használatával kapcsolatban<br/>
            48 órán belül e-mailben értesítjük.
          </p>
          <Button
            text="Vissza a főoldalra"
            action={ () => closeModal() }
          />
        </div>

      </div>
    )
  }
}


export const CustomInput = ({ children, type, name, onChange, error }) => {
  return (
    <div className={"--modal__content__fields-input " + (error ? "error" : "")}>
      <label>
        {children}
        <input
          id={name}
          required
          type={type}
          name={name}
          onChange={onChange}
          autoComplete="on"
        />
      </label>
    </div>
  )
}

export const CustomSelect = ({ children, options, name, onChange, value }) => {
  return (
    <div className="--modal__content__fields-input">
      <label name={name}>
        {children}
        <select
          id={name}
          name={name}
          required
          value={value}
          onChange={onChange}
        >
          {options.map((option, index) => {
            return (
              <option
                key={index}
                disabled={option.disabled ? true : false}
                value={option.value}
              >
                {option.title}
              </option>
            )
          })}
        </select>
      </label>
    </div>
  )
}

export const CustomCheckbox = ({ name, isRequired, label, onChange, value }) => {
  return (
    <div className="--modal__content-input__checkbox">
      <label name={name}>
        <input
          id={name}
          name={name}
          required={isRequired ? true : false}
          type="checkbox"
          onChange={onChange}
          checked={value}
        />
        <span className="--modal__content-input__checkmark"></span>
        <div
          dangerouslySetInnerHTML={{__html: label}}
        />
      </label>
    </div>
  )
}


export default Modal
