import React, { useEffect } from "react";

// Cookie
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

// Components
import Icons from "../../misc/Icons";


const Cookie = ({isCookiesAccepted, setIsCookiesAccepted}) => {
	const cookieName = "EnviMAP";

	// Get initial value
	useEffect(() => {
    const cookieValue = getCookieConsentValue(cookieName);
		setIsCookiesAccepted(cookieValue ? true : false);
  }, [setIsCookiesAccepted]);

	return (
		<CookieConsent
			disableStyles={true}
			containerClasses={"cookie " + (isCookiesAccepted ? "hidden" : "")}
			contentClasses="cookie__body"
			buttonWrapperClasses="cookie__button"
			buttonText={<Icons.Done/>}
			cookieName={cookieName}
			onAccept={() => setIsCookiesAccepted(true)}
		>
			<div className="cookie__body__icon">
				<Icons.Cookie color={"white"}/>
			</div>
			<div className="cookie__body__content">
				<p>A weboldal sütiket használ a felhasználói élmények növeléséhez.</p>
				<a
					className="cookie__body__content__link"
					href="/Cookie_Szabalyzat-EnviMAP.pdf"
					target="_blank"
					rel="noreferrer"
				>
					<p>További információk</p>
					<Icons.ArrowForward/>
				</a>
			</div>
		</CookieConsent>
	)
};

export default Cookie;