import React, { useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Data
const KeyBenefitsData = [
  {
    leadTitle: "Innovatív erdőleltározás",
    title: "Légi lézerszkennelés - Térinformatika",
    text: "Az EnviMAP Erdő szolgáltatás a légi LiDAR technológiával végzett, távérzékelt adatgyűjtésre épít, mely során az erdőterületek valósághű digitális modelljét hozzuk létre. Nagy hatékonyságú lézerszkennerünk és precíz térinformatikai feldolgozásunknak köszönhetően készen tudjuk adni a faegyedek, faállományok mennyiségi és minőségi jellemzőit, valamint térbeli elhelyezkedését.",
  },
  {
    leadTitle: "Hatékonyságnövelés",
    title: "Fatérfogat adatok terepi felvételezés nélkül",
    text: "Erdeit már felmértük. Terepi kiszállás nélkül rövid időn belül több száz vagy ezer hektár erdőterületről kaphat topográfiai és erdészeti adatokat. Tegye egyszerűbbé és hatékonyabbá munkafolyamatait.",
  }
];

// Component
const KeyBenefits = () => {
  const images = useStaticQuery(graphql`
    query keybenefits {
      allFile(
        filter: {
          absolutePath: { regex: "/(keybenefits)/" }
          sourceInstanceName: { ne: "markdown" }
        }
        sort: {fields: name}
      ) {
        edges {
          node {
            childrenImageSharp {
              gatsbyImageData(
                blurredOptions: { toFormat: WEBP }
                placeholder: BLURRED
                formats: WEBP
                breakpoints: [378, 672, 980, 1200, 1568]
              )
            }
            name
            sourceInstanceName
          }
        }
      }
    }
  `);
  const keybenefitsImg = images.allFile;

  return (
    <section className="--key-section">
      {KeyBenefitsData.map((keybenefit, index) => {
        return (
          <div
            key={index}
            className="--key-section__item"
          >
            <ParallaxImage
              imgObject={keybenefitsImg}
              index={index}
            />
            
            <div className="--key-section__item__text-col">
              <div className="--key-section__item__text-col__heading">
                <h4>{keybenefit.leadTitle}</h4>
                <h2>{keybenefit.title}</h2>
              </div>
              <p>{keybenefit.text}</p>
            </div>
          </div>
        )
      })}
    </section>
  )
};


const ParallaxImage = ({imgObject, index}) => {
  const containerRef = useRef();

  return (
    <div
      ref={containerRef}
      className="--key-section__item__img-col"
    >
      <div>
          <GatsbyImage
            image={getImage(
              imgObject.edges[index].node.childrenImageSharp[0].gatsbyImageData
            )}
            loading={"lazy"}
            alt={imgObject.edges[index].node.name}
          />
      </div>
    </div>
  )
};

export default KeyBenefits;
