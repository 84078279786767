import * as React from "react";
import { useState, createContext, useEffect } from "react";
import "../../style/main.scss";

import Header from "./Header";
import Hero from "../Sections/Hero";
import Footer from "./Footer";
import Container from "./Container";
import Modal from "../Modal";
import Cookie from "../Molecules/Cookie";

export const ModalContext = createContext();

const Layout = ({ children }) => {
  const [isCookiesAccepted, setIsCookiesAccepted ] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    isModalOpen ? OpenModalFunction() : (document.body.style.overflow = "");
    return () => {}
  }, [isModalOpen]);
  
  const OpenModalFunction = () => {
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);
  };

  return (
    <>
      <ModalContext.Provider
        value={{ modalOpen: setIsModalOpen }}
      >
        <Modal
          style={isModalOpen ? { display: "block" } : { display: "none" }}
          setIsOpen={isModalOpen}
        />
        <Header />
        <Hero />
        <Container>{children}</Container>
        <Footer />
        <Cookie
          isCookiesAccepted={isCookiesAccepted}
          setIsCookiesAccepted={setIsCookiesAccepted}
        />
      </ModalContext.Provider>
    </>
  )
};

export default Layout;
