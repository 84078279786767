import React from "react"

const Row = props => {
  const gap = props.gap
  const justify = props.justify

  let gapClasses = []
  let justifyClasses = []

  const gapList = {
    xs: "--gap-xs",
    sm: "--gap-sm",
    md: "--gap-md",
    lg: "--gap-lg",
    xl: "--gap-xl",
    xxl: "--gap-xxl",
  }

  const justifyList = {
    xsFlexStart: "--justify-xs-flex-start",
    xsFlexEnd: "--justify-xs-flex-end",
    xsFlexCenter: "--justify-xs-center",
    xsSpaceBetween: "--justify-xs-space-between",
    xsSpaceAround: "--justify-xs-space-around",

    smFlexStart: "--justify-sm-flex-start",
    smFlexEnd: "--justify-sm-flex-end",
    smFlexCenter: "--justify-sm-center",
    smSpaceBetween: "--justify-sm-space-between",
    smSpaceAround: "--justify-sm-space-around",

    mdFlexStart: "--justify-md-flex-start",
    mdFlexEnd: "--justify-md-flex-end",
    mdFlexCenter: "--justify-md-center",
    mdSpaceBetween: "--justify-md-space-between",
    mdSpaceAround: "--justify-md-space-around",

    lgFlexStart: "--justify-lg-flex-start",
    lgFlexEnd: "--justify-lg-flex-end",
    lgFlexCenter: "--justify-lg-center",
    lgSpaceBetween: "--justify-lg-space-between",
    lgSpaceAround: "--justify-lg-space-around",

    xlFlexStart: "--justify-xl-flex-start",
    xlFlexEnd: "--justify-xl-flex-end",
    xlFlexCenter: "--justify-xl-center",
    xlSpaceBetween: "--justify-xl-space-between",
    xlSpaceAround: "--justify-xl-space-around",

    xxlFlexStart: "--justify-xxl-flex-start",
    xxlFlexEnd: "--justify-xxl-flex-end",
    xxlFlexCenter: "--justify-xxl-center",
    xxlSpaceBetween: "--justify-xxl-space-between",
    xxlSpaceAround: "--justify-xxl-space-around",
  }

  if (gap) {
    gap.forEach(element => {
      gapClasses.push(gapList[element])
    })
    gapClasses = gapClasses.join(" ")
  }

  if (justify) {
    justify.forEach(element => {
      justifyClasses.push(justifyList[element])
    })
    justifyClasses = justifyClasses.join(" ")
  }

  return (
    <div className={"--row " + gapClasses + " " + justifyClasses}>
      {props.children}
    </div>
  )
}

export default Row
