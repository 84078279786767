import React from 'react';

const ContainerFluid = ({ children, justSmall }) => {
  return (
    <div className={`--container-fluid ${justSmall ? "--container-fluid-just-small" : ""}`}>
      {children}
    </div>
  )
}

export default ContainerFluid
