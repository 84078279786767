import React from "react";

const DeviceFrame = ({fit, children}) => {
  return (
    <div className="--device-frame">
      <div className="--device-frame__screen">
        <div className={"--device-frame__screen__" + (!fit ? "content" : "content-fit")}>
          {children}
        </div>
      </div>
    </div>
  )
};

export default DeviceFrame;
